import { useState, useRef } from "react";
import { motion } from 'framer-motion';
import { slideIn } from "../utils/motion";
import { FaTelegram, FaTwitter } from "react-icons/fa";
import { AiFillTwitterCircle, AiOutlineCopy } from "react-icons/ai"
import MarqueeImage from "../utils/MarqueeImage";
import CA from "../utils/Ca";

import AOS from 'aos';
import 'aos/dist/aos.css'

const About = () => {
    AOS.init({ once: true });

    const [canPlay, setCanPlay] = useState(false);

    return (<div id="about" className="  text-left w-full relative  px-4 md:w-[90%] p-4 mb-20 items-start gap-5 flex flex-col text-[#000]">
        <h1 className="font-bold text-[30px] ">COMMUNITY COIN TO GIVE BACK AND IMMORTALIZE MEWN CULTURE</h1>
        <p className="uppercase">wen? wen? wen? $mewn</p>
        <p className="font-bold">Soon was born to push limits of Solana</p>
        <p className="muted text-sm">• COMMUNITY COIN TO GIVE BACK AND IMMORTALIZE MEWN CULTURE </p>
        <br />

        <p className="muted text-sm">Token Address </p>
        <h1 className="font-bold text-[14px] md:text-[25px] underline">5JSE5doo9Bnpw9yEzrdEjwjx3pt6PYbzePP5P38wTDb2</h1>


        <button>       <a href="https://dexscreener.com/solana/C9N1hpSoX8GLhWqUWMCWDCwKUUp9kQCdaqTQtpy98K1M" className='bg-[#000] p-2 rounded-md  text-[#fff] uppercase'>chart       </a></button>
        <br />
        <div className="flex flex-col items-center"><img src="../img/twt.jpg" alt="twitter" /></div>

    </div>);
}

export default About;