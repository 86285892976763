import { motion } from "framer-motion";
const Tokenomics = () => {

    const container = {
        hidden: { opacity: 1, scale: 0 },
        visible: {
            opacity: 1,
            scale: 1,
            transition: {
                delayChildren: 0.5,
                staggerChildren: 0.5
            }
        }
    }

    const item = {
        hidden: { y: 20, opacity: 0 },
        visible: {
            y: 0,
            opacity: 1,
            transition: { duration: 0.5 }
        }
    }

    const tokenData = [
        {
            id: 1,
            title: '2% Buy & Sell',
            body: '$SHIB+ available on Uniswap with 2% BUY and SELL taxes'

        },
        {
            id: 2,
            title: 'Charts',
            body: '$SHIB+ Live trading is live on Dextools'

        },
        {
            id: 3,
            title: 'Secure & Verified',
            body: '100% Score Awarded by Snifer Token Scure and Verified Contract'

        },
    ]


    return (
        <motion.div id="token" className="w-[100%] py-10  md:w-[100%] bg-[#000] flex flex-col items-center mx-auto"
            variants={container}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
        >
            <div className="w-[80%] text-white flex flex-col items-start gap-5">
                <h1>TOKENOMICS</h1>
                <p className="opacity-50">A total supply of 1 billion tokens</p>
                <div className="flex flex-col gap-2">

                    <h1><span className="font-bold uppercase">Liquidity</span> 90%</h1>
                    <h1><span className="font-bold uppercase">Marketing</span> 10%</h1>
                    <h1><span className="font-bold uppercase">Team</span> 0%</h1>
                </div>

                <p className="opacity-50">Wen? $MEWN</p>
                <button>       <a href="https://raydium.io/swap?outputCurrency=5JSE5doo9Bnpw9yEzrdEjwjx3pt6PYbzePP5P38wTDb2" className='border-[#ffffff70] border-2 p-2 rounded-md  text-[#fff] uppercase'>Buy $MEWN       </a></button>


            </div>



        </motion.div>
    );
}

export default Tokenomics;