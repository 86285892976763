import { useState, useRef } from "react";
import { motion } from 'framer-motion';
import { loaderVariants, slideIn } from "../utils/motion";
import { FaTelegram } from "react-icons/fa";

import { AiFillTwitterCircle, AiOutlineCopy, AiOutlineArrowRight, AiOutlineTwitter, AiOutlineRight, AiOutlineWallet } from "react-icons/ai"
import Button from "../utils/Button";
import AnimeLogo from "../utils/AnimeLogo";
import CA from "../utils/Ca";
import AOS from 'aos';
import 'aos/dist/aos.css'


const Hero = ({ pryColor
    , secColor }
) => {
    AOS.init({ once: true });

    const [isCopied, setIsCopied] = useState(false);
    const handleCopied = () => {
        setIsCopied(true);
        setTimeout(() => {
            setIsCopied(false);
        }, 5000)
    }

    const contractRef = useRef();

    const copyContractText = () => {
        const text = contractRef.current.textContent;
        navigator.clipboard.writeText(text);
        console.log('Text copied: ' + text);
        handleCopied();
    };

    const buttonStyle = "bg-[#6495ED] text-[#fff] py-2 px-4 md:px-5 text-14px md:text-[18px] transform duration-300 hover:scale-110 rounded-md"

    return (<div className=" relative hero-section w-[full] md:w-[100%] mx-auto  flex  flex-col px-4 p-20 md:px-4 mt-10 py-20 items-center justify-center pt-0 gap-5">

       <img src="../img/wen.png" alt="wen" className="" />
        <h1 className="uppercase text-[20px] text-center">COMMUNITY COIN TO GIVE BACK AND IMMORTALIZE mewn CULTURE</h1>
        <a href="https://x.com/wenwencoin/status/1750930201070928141?s=20" className="font-bold underline">LEARN MORE</a>
    </div>);
}

export default Hero;