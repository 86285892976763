import { motion } from 'framer-motion';
import { slideIn } from "../utils/motion";
import { AiFillInstagram, AiFillYoutube, AiOutlineArrowRight, AiOutlineTwitter } from 'react-icons/ai';
import Button from '../utils/Button';
import { SiTelegram } from 'react-icons/si';
import { FaTelegram } from 'react-icons/fa';

const Header = () => {

    return (<motion.div className=" w-full text-[#000] py-5 md:py-1  text-[16px] font-semibold flex  md:flex-row z-20 gap-5 items-center  justify-between md:justify-between px-10"
        variants={slideIn('up', 'spring', 0.1, 0.4)}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}

    >
        <div className=' flex flex-row items-center justify-start rounded-full'>
            <img className='w-[70px] rounded-full' src="../img/logo.png" alt="logo" />
        
        </div>
        <motion.div className=' uppercase  flex flex-row gap-3 md:gap-5  items-center  justify-center'
        >
            <a href="https://t.me/sol_wenmewn" className='text-[25px]'>
                <SiTelegram />
            </a>
            <a href="https://x.com/sol_wenmewn" className='text-[25px]'>
                <AiOutlineTwitter />
            </a>
            <a href="#about" className='hidden md:block'>About</a>
            <a href="#token" className='hidden md:block'>Tokenomics</a>
            <a href="https://raydium.io/swap?outputCurrency=5JSE5doo9Bnpw9yEzrdEjwjx3pt6PYbzePP5P38wTDb2" className='bg-[#000] p-2 rounded-md  text-[#fff]'>
                <button>Buy $WEMN</button>
            </a>
            

        </motion.div>

    </motion.div>);
}

export default Header;